import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class UserComponent {

  data: any;

  constructor(private router: ActivatedRoute) {
    router.params.subscribe(value => {
      this.data = this.getUserData(value.id);
    });
  }

  private getUserData(id: any) {
    if (id === 'patrik') {
      return this.getPatrikData();
    } else if (id === 'erik') {
      return this.getErikData();
    }
    return {
      data: []
    };
  }

  private getErikData() {
    return {
      data: [
        {
          no: 1,
          name: 'Erik narodeniny',
          value: 30,
          date: new Date('2020-06-22')
        },
        {
          no: 2,
          name: 'Erik meniny',
          value: 30,
          date: new Date('2022-02-02')
        },
        {
          no: 3,
          name: 'Vianoce 2022',
          value: 40,
          date: new Date('2022-12-24')
        },
        {
          no: 4,
          name: 'Erik meniny',
          value: 20,
          date: new Date('2023-02-02')
        },
        {
          no: 5,
          name: 'Vianoce 2024',
          value: 50,
          date: new Date('2024-12-24')
        }
      ],
      sum: {
        value: 170
      }
    };
  }

  private getPatrikData() {
    return {
      data: [
        {
          no: 1,
          name: 'Patrik narodeniny',
          value: 30,
          date: new Date('2020-06-17')
        },
        {
          no: 2,
          name: 'Patrik meniny',
          value: 30,
          date: new Date('2022-07-06')
        },
        {
          no: 3,
          name: 'Vianoce 2022',
          value: 40,
          date: new Date('2022-12-24')
        },
        {
          no: 4,
          name: 'Patrik meniny',
          value: 20,
          date: new Date('2022-07-06')
        },
        {
          no: 5,
          name: 'Vianoce 2024',
          value: 50,
          date: new Date('2024-12-24')
        }
      ],
      sum: {
        value: 170
      }
    };
  }


}
